document.addEventListener('turbo:load', () => {
  const notice = document.querySelector('#notice')
  if (notice) {
    notice.style.opacity = 1
    setTimeout(() => {
      const id = setInterval(() => {
        const opacity = notice.style.opacity
        if (opacity > 0) {
          notice.style.opacity = opacity - 0.01
        } else {
          notice.style.display = 'none'
          clearInterval(id)
        }
      }, 20)
    }, 4000)
  }
})
